<template>
	<div>
		<div class="humanitarian-title"><span>项目地图</span></div>
		<div class="projectmap">
			<div class="projectcontent">
				<div style="width: 50%;">
					<div id="myChart" style="width: 100%;height: 500px;"></div>
					<div class="tb-list">
						<div class="tb-item" v-for="item in tabList">
							<div class="image"></div>
							<div>
								<div style="color: rgb(209,41,45);font-size: 22px;">{{item.project_num}}</div>
								<div style="font-size: 14px;">{{item.title}}</div>
							</div>
						</div>
					</div>
				</div>
				<div style="height: 100%;width: 50%;padding: 0 20px;box-sizing: border-box;">
					<div class="biao" style="margin-top: 30px;">
						<div class="title">年度项目数(个)</div>
						<div class="biao-list">
							<div class="biao-item" v-for="item in xmNum">
								<div class="zfx" style="background: rgb(221,157,45);"></div>
								<div style="margin-right: 20px;">{{item.year}}年</div>
								<div style="color: rgb(230,182,98);">{{item.project_num}}</div>
							</div>
						</div>
					</div>
					<div class="biao" style="margin-top: 15px;">
						<div class="title">资金来源(万元)</div>
						<div class="biao-list" >
							<div class="biao-item" style="min-width: 220px;" v-for="item in zjNum">
								<div class="zfx" style="background: rgb(209,41,45);"></div>
								<div style="margin-right: 10px;">{{item.title}}</div>
								<div>{{item.fund}}</div>
							</div>
						</div>
					</div>
					<div class="biao" style="margin-top: 15px;">
						<div class="title" style="display: flex;justify-content: space-between;align-items: center;">
							<div>项目公示</div>
							<div style="font-size: 12px;color: #ABABAB;" @click="jump">查看更多></div>
						</div>
						<div class="biao-list">
							<div class="biao-item" style="width: 100%;" v-for="item in projectlist" @click="jumpXmgs(item.id)">
								<div class="zfx" style="background: rgb(41,123,209);"></div>
								<div class="title-text">{{item.title}}</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="map" @mouseleave="hidemapinfo">
					
					<!-- <div class="mapinfo" v-show="isShowtable">
						<h2>{{ getprojectdetail.punctuation_name || '' }}灾区</h2>
						<div class="projectnum">
							<ul>
								<li>项目总数(个)</li>
								<li>{{ getprojectdetail.project_nums || '' }}</li>
							</ul>
							<ul>
								<li>项目总投资(元)</li>
								<li>{{ getprojectdetail.project_investment || '' }}</li>
							</ul>
						</div>
						<div class="projectinfo">
							<div class="contenttitle">
								<span>项目名称</span>
								<span>项目总数(个)</span>
								<span>项目总金额(元)</span>
							</div>
							<div class="projectdetail">
								<ul>
									<li v-for="(item, index) in donatenews" :key="item.id">
										<span>{{ item.project_name || '' }}</span>
										<span>{{ item.project_info_num || '' }}</span>
										<span>{{ item.project_info_money || '' }}</span>
									</li>
								</ul>
							</div>
						</div>
					</div> -->
				<!-- </div> -->
				<!-- <div class="donation">
					<h2>项目捐赠统计信息</h2>
					<div class="donate-title">
						<span>捐赠方</span>
						<span>捐赠项目数</span>
						<span>捐款总金额(元)</span>
					</div>
					<el-collapse accordion>
						<el-collapse-item v-for="(item, index) in donatestatic" :key="item.id">
							<template slot="title">
								<span>{{ item.donors || '' }}</span>
								<span>{{ item.donation_project_num || '' }}</span>
								<span>{{ item.donation_amount || '' }}</span>
							</template>
							<div>{{ item.donation_info || '' }}</div>
						</el-collapse-item>
					</el-collapse>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
// import 'echarts/map/js/province/sichuan'
// import 'echarts/map/js/china'
import apiUrls from '@api';
export default {
	name: 'Projectmap',
	data() {
		return {
			geoCoordMap: {
				// 成都: [104.07, 30.67],
			},
			introData: [],
			isShowtable: false,
			donatestatic: [],
			donatenews: [],
			getprojectdetail: {},
			
			xmNum: [],
			zjNum: [],
			tabList: [],
			projectlist: [],
			info: '',
			detailData: '',
		};
	},
	created() {},
	mounted() {
		//zbtx_dtsj
		this.getList("xmycz_xmjj_xmdtfb").then((res) => {
		  this.drawMap(res);
		});
		
		this.getXm()
		this.getZj()
		this.getTab()
		this.getXmgs()
	},
	methods: {
		jumpXmgs(id) {
			this.$router.push({path:'/detailShow', query: {id: id}})
		},
		jump() {
			this.$router.push({path:'/infoopen'})
		},
		getXmgs(){
			apiUrls.getinfoopen({node: 'xxgk_xmgs'}).then(res=>{
				this.projectlist= res.results.data.splice(0,5)
				// console.log(this.projectlist)
			})
		},
		getXm() {
			apiUrls.gethumanmap({ node: 'xmycz_xmjj_ndxms',size: '18' }).then(res => {
				this.xmNum = res.results.data;
				// console.log(this.xmNum)
			});
		},
		getZj() {
			apiUrls.gethumanmap({ node: 'xmycz_xmjj_zjly' }).then(res => {
				this.zjNum = res.results.data;
				// console.log(this.zjNum)
			});
		},
		getTab() {
			apiUrls.gethumanmap({ node: 'xmycz_xmjj_xmlxfbs' }).then(res => {
				this.tabList = res.results.data;
				// console.log(res.results.data)
			});
		},
		hidemapinfo() {
			this.isShowtable = false;
		},
		drawMap(data) {
			// console.log(data)
		  // 基于准备好的dom，初始化echarts实例
		  let myChart = this.$echarts.init(document.getElementById("myChart"));
		  // 绘制图表
		  //选项配置
		  var option = {
		    geo: {
		      show: true,
		      map: "sichuan",
		      itemStyle: {
		        normal: {
		          areaColor: "#FBFAFA",
		          shadowColor: "rgba(0, 0, 0, 0.4)",
		          shadowBlur: 20,
		          shadowOffsetX: -30,
		          shadowOffsetY: 20,
		          borderWidth: 0,
		        },
		        emphasis: {
		          areaColor: "#2AB8FF",
		          borderWidth: 0,
		          color: "green",
		          label: {
		            show: false,
		          },
		        },
		      },
		    },
		   tooltip : {
		      formatter:function(params){
		        var str =
		        `<div class="tooltipBox">
		          <p style="color:#fff">地区：${params.data.name}</p>
		          <p style="color:#fff">项目数量：${params.value}个</p>
		        </div>`
		        return str
		      }
		    },
			legend: {
				orient: 'vertical',
				x:'70%',
				y: '80%',
				data:['市州项目数']
			},
		    series: [
				{
					name: "市州项目数",
					type: 'map',
					map: "sichuan",
					roam: false,
					itemStyle:{
						// normal:{label:{show:true}},
						emphasis:{label:{show:true}}
					},
					data:data
				},
		    ],
		  }
		  myChart.setOption(option);
		},
		getList(node) {
		  return apiUrls.selfDynamicList({ node,size: '21' }).then((res) => {
		    let pointData = [];
		    res.results.data.forEach((el) => {
		      let obj = {
		        name: el.title,
		        value: el.project_num,
		      };
		      pointData.push(obj);
		    });
		    return pointData;
		  });
		},
	}
};
</script>

<style lang="less" scoped>
.projectmap {
	width: 100%;
	box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
}
.humanitarian-title {
	margin-top: 10px;
	font-size: 22px;
	color: #333333;
	font-weight: bold;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
	span {
		&:nth-child(1) {
			display: flex;
			&::before {
				content: '';
				display: block;
				height: 20px;
				width: 20px;
				background: url('../../../assets/img/title.png') no-repeat center center;
			}
		}
		&:nth-child(2) {
			color: #ababab;
			font-size: 17px;
		}
	}
}
.projectcontent {
	// min-height: 515px;
	display: flex;
	width: 100%;
	background-color: #ffffff;
	padding-bottom: 30px;
	.tb-list {
		width: 100%;
		// height: 160px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0 20px;
		margin-bottom: 30px;
		&:after{
		  content:'';
		  width: 150px;
		}
		.tb-item {
			margin: 0 10px;
			display: flex;
			align-items: center;
			width: 150px;
			height: 60px;
			.image {
				border-radius: 50%;
				background-image: url(../../../assets/img/volunter2-bg.png);
				background-size: 100% 100%;
				width: 35px;
				height: 35px;
				margin-right: 5px;
			}
		}
	}
	.biao {
		box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
		width: 85%;
		padding: 15px 10px 0 10px;
		margin: 0 auto;
		.title {
			width: 100%;
			line-height: 30px;
			border-bottom: 1px solid #cacaca;
			font-size: 16px;
			font-weight: bold;
		}
		.biao-list {
			font-size: 14px;
			width: 100%;
			padding-top: 20px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			&:after{
			  content:'';
			  min-width: 130px;
			}
			.biao-item {
				min-width: 130px;
				display: flex;
				align-items: center;
				margin-bottom: 20px;
			}
			.title-text {
				width: 95%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			.zfx {
				width: 10px;
				height: 10px;
				margin-right: 5px;
			}
		}
	}
	.map {
		width: 100%;
		// display: flex;
		// justify-content: space-between;
		// border: 1px solid #666666;
		// position: relative;
		// margin: 45px 0 55px 120px;
		
		.mapinfo {
			position: absolute;
			bottom: -28px;
			left: 111px;
			width: 331px;
			min-height: 200px;
			background-color: #ffffff;
			border: 1px solid #d72829;
			border-radius: 8px;
			h2 {
				color: #d72829;
				text-align: center;
				margin-top: 7px;
				margin-bottom: 4px;
			}
			.projectnum {
				// padding: 0 10px;
				// display: flex;
				height: 53px;
				justify-content: space-around;
				border-top: 1px solid #d72829;
				border-bottom: 1px solid #d72829;
				ul {
					li {
						color: #333333;
						&:nth-child(1) {
							margin-top: 7px;
							margin-bottom: 9px;
							font-size: 14px;
							font-weight: bold;
						}
						&:nth-child(2) {
							text-align: center;
							color: #ff2828;
							font-weight: bolder;
							font-size: 22px;
						}
					}
				}
			}
			.projectinfo {
				width: 310px;
				min-height: 20px;
				padding: 5px 7px 0px 3px;
				.contenttitle {
					width: 320px;
					height: 27px;
					background: #faf0e7;
					font-size: 12px;
					color: #b47a42;
					font-weight: bold;
					span {
						line-height: 27px;
						&:nth-child(1) {
							margin-left: 15px;
						}
						&:nth-child(2) {
							margin-left: 64px;
						}
						&:last-child {
							margin-left: 30px;
						}
					}
				}
			}
			.projectdetail {
				padding-left: 15px;
				font-size: 12px;
				ul {
					li {
						margin-bottom: 16px;
						display: flex;
						&:nth-child(1) {
							margin-top: 7px;
						}
						span {
							display: block;
							&:nth-child(1) {
								min-width: 107px;
							}
							&:nth-child(2) {
								width: 71px;
								text-align: right;
								font-size: 14px;
								font-weight: bold;
								color: #ff2828;
							}
							&:last-child {
								width: 84px;
								text-align: right;
								margin-left: 26px;
								font-size: 14px;
								font-weight: bold;
								color: #ff2828;
							}
						}
					}
				}
			}
		}
	}
	.donation {
		width: 455px;
		min-height: 459px;
		padding: 0 18px;
		box-shadow: 0px 6px 18px 0px rgba(78, 78, 78, 0.33);
		background-color: #ffffff;
		margin: 26px 28px 30px 115px;
		background: url('../../../assets/img/rendao.png') no-repeat;
		h2 {
			text-align: left;
			width: 130px;
			position: relative;
			left: 17px;
			top: 25px;
			color: #b47a42;
			font-weight: bolder;
		}
		.donate-title {
			margin-top: 47px;
			height: 37px;
			font-size: 18px;
			color: #c87d7d;
			font-weight: bold;
			border-top: 1px dashed #f5f5f5;
			line-height: 37px;
			span {
				&:nth-child(2) {
					margin-left: 72px;
				}
				&:nth-child(3) {
					margin-left: 38px;
				}
			}
		}
		// table {
		//   width: 418px;
		//   margin-left: 19px;
		//   margin-top: 46px;
		//   tr {
		//     border: 1px dashed #666666;
		//     border-left: none;
		//     border-right: none;
		//   }
		//   th {
		//     height: 37px;
		//     line-height: 37px;
		//     text-align: left;
		//     font-size: 17px;
		//     color: #C87D7D;
		//   }
		//   td {
		//     // border: 1px solid #666666;
		//     height: 37px;
		//     line-height: 37px;
		//     font-size: 16px;
		//     // color: #333333;
		//     &:nth-child(2) {
		//       text-align: right;
		//       padding-right: 35px;
		//     }
		//     &:nth-child(3) {
		//       text-align: right;
		//       padding-right: 50px;
		//     }
		//   }
		// }
	}
}
</style>
<style>
.el-collapse {
	/* margin-top: 46px; */
	border-top: 1px dashed #e5e5e5 !important;
	border-bottom: 1px dashed #e5e5e5 !important;
	border-left: none !important;
	border-right: none !important;
}
#el-collapse-head-6674 {
	border: 1px dashed #ebeef5;
}
.el-collapse-item__content {
	background-color: #f5f5f5;
	padding-bottom: 0;
	padding: 13px 10px;
}
.el-collapse-item__header {
	border: 1px dashed #ebeef5 !important;
	background-color: transparent !important;
	border-left: none !important;
	border-right: none !important;
}
.el-collapse-item__header span {
	width: 112px;
	height: 48px;
	overflow: hidden;
	font-size: 16px;
	color: #333333;
}
.el-collapse-item__header span:nth-child(2) {
	display: block;
	width: 88px;
	height: 48px;
	text-align: right;
	overflow: hidden;
	width: 88px;
	margin-left: 20px;
}
.el-collapse-item__header span:nth-child(3) {
	width: 116px;
	height: 48px;
	overflow: hidden;
	margin-left: 39px;
	text-align: right;
	display: block;
	width: 116px;
}
.el-collapse-item__content div {
	/* margin-top: 10px; */
}
</style>
